
import NewMint from "./views/NewMint/index.js";
import Whitelist from "./views/Whitelist/index.js";
// import HoldMint from "./views/HoldMint/index.js";


var routes = [
  {
    layout: "/home",
    path: "/",
    component: Whitelist,
    hidden: true,
  },
  // {
  //   layout: "/home",
  //   path: "/allNFT",
  //   name: "All NFT's",
  //   component: AllNFT,
  //   icon: "tim-icons icon-notes",
  // },
  {
    layout: "/home",
    path: "/whitelist",
    name: "White List",
    component: Whitelist,
    icon: "tim-icons icon-bullet-list-67",
  },
  {
    layout: "/home",
    path: "/newMint",
    name: "Airdrop",
    component: NewMint,
    icon: "tim-icons icon-simple-add",
  },
  // {
  //   layout: "/home",
  //   path: "/holdMint",
  //   name: "Mint Hold NFT",
  //   component: HoldMint,
  //   icon: "tim-icons icon-bullet-list-67",
  // },
];

export default routes;