import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import React, { Component } from 'react'
import Button from '@material-ui/core/Button';
import { validate } from 'wallet-address-validator';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { setLoader } from '../../store/actions/Auth.js';
import { Token, TokenAddress, ICOAddress } from '../../store/contract/index';

class NewMint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: '',
      address: '',
    };
  };

  handleEditChange = (e) => this.setState({ [e.target.name]: e.target.value });

  submitNewMint = async () => {
    try {
      let { amount, address } = this.state;

      if (amount == '') {
        EventBus.publish('error', `Please enter the amount of tokens`);
        return;
      };

      if (address == '') {
        EventBus.publish('error', `Please enter the address`);
        return;
      };

      if (!validate(address, 'ETH')) {
        EventBus.publish('error', `Please provide valid address`);
        return;
      };

      const from = (await web3.currentProvider.enable())[0];
      /** Mint New NFT **/
      this.props.setLoader({ message: 'Airdrop in Progress...', status: true });
      await web3.eth.sendTransaction({
        from,
        value: 0,
        to: TokenAddress,
        data: Token.methods.mint(address, amount).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async (receipt) => {
          this.setState({ amount: '', address: '' });
          this.props.setLoader({ status: false });
          EventBus.publish('success', `NFT Airdrop successfully`);
        })
    } catch (e) {
      console.log('********Error = ', e);
      this.props.setLoader({ message: 'Airdrop not Completed...', status: false });
      EventBus.publish('error', `Unable to Airdrop NFT`);
    };
  };

  render() {
    let { amount, address } = this.state;

    return (
      <div className='content'>
        <div className="main-container mint-new">
          <div className='mint-new-NFT'>
            <div className='edit-add row'>
              <div className="edit-add-title col-12">
                <h1 className="text-dark">Airdrop</h1>
              </div>
              <div className="edit-add-body col-12">
                <ValidatorForm onSubmit={this.submitNewMint}>
                  <div className="row">
                    <div className='edit-add-field offset-md-2 col-md-8 col-sm-12 mt-4'>
                      <TextValidator
                        fullWidth
                        type='number'
                        name='amount'
                        value={amount}
                        variant="outlined"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                        label={<label>No. of Tokens <span>*</span></label>}
                      />
                    </div>
                    <div className='edit-add-field offset-md-2 col-md-8 col-sm-12 mt-4'>
                      <TextValidator
                        fullWidth
                        type='text'
                        name='address'
                        value={address}
                        variant="outlined"
                        id='standard-full-width'
                        className='form-input-field'
                        onChange={this.handleEditChange}
                        label={<label>Address <span>*</span></label>}
                      />
                    </div>
                    <div className='edit-add-buttons col-sm-12 mt-4'>
                      <Button className="submit-btn col-4" type='submit' onSubmit={this.submitNewMint}>Submit</Button>
                    </div>
                  </div>
                </ValidatorForm>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(NewMint);