/*========== PAGE LOADER ACTIONS ============= */

export const setLoader = (data) => ({
  type: 'SET_LOADER',
  payload: data,
});

/*========== LOGIN ACTIONS ============= */

export const login = (data) => ({
  type: 'LOGIN',
  payload: data,
});

export const isloginDisabled = () => ({
  type: 'IS_LOGIN_DISABLED',
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const setAddress = (data) => ({
  type: 'SET_ADDRESS',
  payload: data
});

/*========== ALL NFT's ACTIONS ============= */

export const getAllNFT = () => ({
  type: 'GET_ALL_NFT',
});

export const setAllNFT = (data) => ({
  type: 'SET_ALL_NFT',
  payload: data
});

/*========== MINTED NFT's ACTIONS ============= */

export const getMintedNFT = () => ({
  type: 'GET_MINTED_NFT',
});

export const setMintedNFT = (data) => ({
  type: 'SET_MINTED_NFT',
  payload: data
});
