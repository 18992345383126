import { PURGE } from "redux-persist";
import { setToken } from '../axios';

var initialState =
{
  auth: localStorage.getItem('token'),
  address: localStorage.getItem('token'),
  isLoader: { message: 'Please Wait...', status: false },
  allNFTs: [],
  mintedData: [],
};

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {
    case PURGE: return initialState;

    /*========== ADMIN REDUCERS ============= */

    case 'LOGIN':
      localStorage.setItem('token', payload);
      return {
        ...state,
        address: payload,
        auth: payload,
      };

    case 'IS_LOGIN_DISABLED':
      return {
        ...state,
      };

    case 'LOGOUT':
      localStorage.removeItem('token');
      return {
        ...state,
        address: '',
        auth: '',
      };

    case 'SET_ADDRESS':
      return {
        ...state,
        address: payload
      };

    case 'SET_BALANCE':
      return {
        ...state,
        balance: payload
      };

    /*========== LOADER REDUCERS ============= */

    case 'SET_LOADER':
      return {
        ...state,
        isLoader: payload
      };


    /*========== ALL NFT's REDUCERS ============= */

    case 'SET_ALL_NFT':
      return {
        ...state,
        allNFTs: payload
      };

    /*========== MINTED NFT's REDUCERS ============= */

    case 'SET_MINTED_NFT':
      return {
        ...state,
        mintedData: payload
      };

    default:
      return state;
  }
};

export default Auth;