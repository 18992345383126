import moment from 'moment';
import EventBus from 'eventing-bus';
import { connect } from 'react-redux';
import ReactTable from 'react-table-6';
import Button from '@material-ui/core/Button';
import React, { Component, Fragment } from 'react';
import { validate } from 'wallet-address-validator';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import './index.css';
import { web3 } from '../../store/web3';
import { setLoader } from '../../store/actions/Auth'
import { explorer } from '../../store/config';
import { ICO, ICOAddress } from '../../store/contract/index';


class Whitelist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: '',
      addressArray: [],
      whitelistData: [],
      date: [],
      isWhitelistModal: false,
    };
  };

  async componentDidMount() {
    let whitelistData = await ICO.getPastEvents('WhitelistedAdded', { fromBlock: 0, toBlock: 'latest' });
    for (let x = 0; x < whitelistData.length; x++) {
      let time = await web3.eth.getBlock(whitelistData[x]['blockNumber']);
      whitelistData[x]['timestamp'] = time['timestamp'];
    }
    this.setState({ whitelistData });
  };

  toggleWhitelistModal = () => this.setState({ isWhitelistModal: !this.state.isWhitelistModal },
    () => {
      let { isWhitelistModal } = this.state;
      if (!isWhitelistModal) this.setState({ address: '' })
    });

  handleEditChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if ([e.target.name] == 'address') {
      let { addressArray } = this.state;
      let address = e.target.value;
      addressArray = address.split(',');
      this.setState({ addressArray })
    }
  };

  addWhitelist = async () => {
    try {
      let { addressArray } = this.state;

      if (addressArray.length == 0) {
        EventBus.publish('error', `Please enter the addresses`);
        return;
      };
      for (let i = 0; i < addressArray.length; i++) {
        if (!validate(addressArray[i], 'ETH')) {
          EventBus.publish('error', `No. ${i + 1} is not a valid address`);
          return;
        };
        let isWhitelisted = await (ICO.methods.isWhitelisted(addressArray[i]).call());
        if (isWhitelisted) {
          EventBus.publish('error', `No. ${i + 1} Address already whitelisted`);
          return;
        }
      };

      /** Add Whitelist **/
      const from = (await web3.currentProvider.enable())[0];
      this.props.setLoader({ message: 'Whitelisting in Progress...', status: true });
      await web3.eth.sendTransaction({
        from,
        value: 0,
        to: ICOAddress,
        data: ICO.methods.addWhitelisted(addressArray).encodeABI(),
      }).on('transactionHash', (hash) => console.log(`*******hash = `, hash))
        .on('receipt', async (receipt) => {
          this.toggleWhitelistModal();
          let whitelistData = await ICO.getPastEvents('WhitelistedAdded', { fromBlock: 0, toBlock: 'latest' });
          for (let x = 0; x < whitelistData.length; x++) {
            let time = await web3.eth.getBlock(whitelistData[x]['blockNumber']);
            whitelistData[x]['timestamp'] = time['timestamp'];
          }
          this.setState({ address: '', addressArray: [], whitelistData });
          this.props.setLoader({ status: false });
          EventBus.publish('success', `Whitelisted Successfully`);
        })

    } catch (e) {
      this.toggleWhitelistModal();
      console.log('********Error = ', e);
      EventBus.publish('error', `Unable to Whitelist Address`);
      this.props.setLoader({ message: 'Whitelisting not Completed...', status: false });
    };
  };

  render() {
    let { address, whitelistData, isWhitelistModal } = this.state;

    const columns = [
      {
        id: 'returnValues',
        Header: 'Whitelisted Address',
        accessor: whitelistData => whitelistData['returnValues'][0] ? whitelistData["returnValues"][0] : '-',
      },
      {
        id: 'blockNumber',
        Header: 'Date',
        accessor: whitelistData => whitelistData['blockNumber'] ? moment(whitelistData['timestamp'] * 1000).format("lll") : '-',
      },
      {
        id: 'transactionHash',
        Header: 'Transaction Hash',
        accessor: whitelistData => whitelistData['transactionHash']
          ? <a href={`${explorer}/tx/${whitelistData["transactionHash"]}`} target='_blank' style={{ color: '#333645' }}>
            View on Explorer
          </a>
          : '-',
        filterable: false
      }];

    return (
      <div className='content'>
        <div className="main-container mintedNFT">
          <div className='main-container-head mb-3'>
            <p className="main-container-heading">Minted NFT's</p>
            <button onClick={this.toggleWhitelistModal} className="add-btn">Add Whitelist</button>
          </div>
          <Fragment>
            <div className='main-container-head mb-3'>
              <ReactTable
                className="table"
                data={whitelistData}
                resolveData={whitelistData => whitelistData.map(row => row)}
                columns={columns}
                minRows={20}
                filterable={true}
              />
            </div>
          </Fragment>
        </div>

        {/* ---------------ADD WHITELIST MODAL--------------- */}

        <Modal isOpen={isWhitelistModal} toggle={this.toggleWhitelistModal} className="main-modal whitelist-modal">
          <ModalHeader toggle={this.toggleWhitelistModal}>
            <div className="modal-logo">
              <img src={require('../../assets/img/logo.png')} alt="modal-logo" />
            </div>
            <div className="text-center"><h2 className="text-white">ADD WHITELIST</h2></div>
            <div className="text-white"><hr /></div>
          </ModalHeader>
          <ModalBody className="modal-body">
            <ValidatorForm className="validator-form" onSubmit={this.addWhitelist}>
              <div className='row'>
                {/* <div className="col-2"></div> */}
                <div className='col-12'>
                  <TextValidator
                    fullWidth
                    multiline
                    rows={3}
                    type='text'
                    name='address'
                    value={address}
                    placeholder="Enter the Addresses"
                    className='text-field'
                    id='standard-full-width'
                    onChange={this.handleEditChange}
                    helperText="Please seperate Address with comma(,) and don't put space in between."
                  />
                </div>
                {/* <div className="col-2"></div> */}
              </div>
              <div className="row">
                <div className="col-12 pt-4 pb-5 d-flex justify-content-center">
                  <Button type="submit" variant="contained" className="add-btn">
                    Submit
                  </Button>
                </div>
              </div>
            </ValidatorForm>
          </ModalBody>
        </Modal>

      </div>
    );
  }
}

const mapDispatchToProps = {
  setLoader,
};

const mapStateToProps = ({ Auth }) => {
  let { } = Auth;
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Whitelist);