import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import { setAllNFT, setMintedNFT } from '../actions/Auth';

/*========== ALL NFT's FUNCTIONS =============*/
function* getAllNFT() {
  const { error, response } = yield call(getCall, '/nft/getAllNfts');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setAllNFT(response['data']['body']));
};

/*========== MINTED NFT's FUNCTIONS =============*/
function* getMintedNFT() {
  const { error, response } = yield call(getCall, '/users/getminted');
  if (error) EventBus.publish('error', error['response']['data']['message']);
  else if (response) yield put(setMintedNFT(response['data']['body']));
};


function* actionWatcher() {
  yield takeEvery('GET_ALL_NFT', getAllNFT);
  yield takeEvery('GET_MINTED_NFT', getMintedNFT);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
